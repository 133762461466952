<template>
  <span
    class="my-copy-icon"
    @click="makeCopyToast"
  >
    <feather-icon
      icon="CopyIcon"
    />

  </span>
</template>

<script>
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
  },
  setup(props) {
    const toast = useToast()
    const makeCopyToast = () => {
      if (props.value !== null && props.value !== '') {
        window.navigator.clipboard.writeText(props.value)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Copy Success',
            icon: 'BellIcon',
            text: props.value,
            variant: 'primary',
          },
        })
      }
    }

    return {
      makeCopyToast,
    }
  },
}
</script>

<style scoped>
.my-copy-icon {
  cursor: pointer;
}

.my-copy-icon:hover {
  color: var(--primary)
}
</style>
