<template>
  <TagbyActionCard>
    <template #left>
      <b-button
        variant="outline-primary"
        block
        :disabled="!brandSns || brandSns.state === 'INACTIVE'"
        @click="$emit('makeWebAuthTicket')"
      >
        <b-spinner
          v-if="isWaitingTicket"
          small
        />
        웹 인증 티켓 발급
      </b-button>
      <b-button
        variant="outline-danger"
        block
        :disabled="!brandSns || brandSns.state === 'INACTIVE'"
        @click="$emit('turnOnDeleteBrandSnsModal')"
      >
        삭제
      </b-button>
    </template>

    <template #right>
      <b-button
        variant="outline-primary"
        block
        :disabled="!brandSns || brandSns.state !== 'ACTIVE' || brandSns.access_token == null"
        @click="$emit('turnOnAddPartnershipSidebar')"
      >
        파트너십 추가
      </b-button>
    </template>
  </TagbyActionCard>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import TagbyActionCard from '@/components/TagbyActionCard.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner,
    TagbyActionCard,
  },
  emits: [
    'save',
    'turn-on-delete-modal',
  ],
  props: {
    brandSns: {
      type: Object,
      default: () => {},
    },
    isWaitingTicket: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
