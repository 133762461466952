<template>
  <BCard title="파트너십 목록">
    <BListGroup>
      <PartnershipListItem
        v-for="partnership in partnershipList"
        :key="partnership.id"
        :username="partnership.creator_username"
        :permissionStatus="partnership.permission_status"
      />
    </BListGroup>
  </BCard>
</template>

<script>
import {
  BCard,
  BListGroup,
  BListGroupItem,
  BBadge,
} from 'bootstrap-vue'
import PartnershipListItem from './PartnershipListItem.vue'

export default {
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BBadge,
    PartnershipListItem,
  },
  props: {
    partnershipList: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
