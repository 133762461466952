<template>
  <BCard title="기본 정보">
    <BRow>
      <BCol cols="4">
        <BFormGroup label="ADV_IDX">
          <BFormInput
            :value="adv.idx"
            disabled
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="ADV_NAME">
          <BFormInput
            :value="adv.name"
            disabled
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="ADV_COMPANY">
          <BFormInput
            :value="adv.company_name"
            disabled
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol cols="4">
        <BFormGroup label="SNS_ID">
          <BFormInput
            :value="brandSns.username"
            disabled
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="STATE">
          <BFormInput
            :value="brandSns.state"
            disabled
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="CREATED_AT">
          <BFormInput
            :value="brandSns.created_at"
            disabled
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  props: {
    adv: {
      type: Object,
      default: () => {},
    },
    brandSns: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
