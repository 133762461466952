<template>
  <tagby-sidebar
    :visible="visible"
    width="30%"
    @hidden="$emit('hidden')"
  >
    <template #title>
      파트너십 추가
    </template>
    <template #content>
      <b-row>
        <b-col>
          <b-form-group
            label="username"
          >
            <BInputGroup>
              <b-form-input
                :value="username"
                @input="$emit('inputUsername', $event)"
                @keyup.enter="$emit('search')"
              />
              <BInputGroupAppend>
                <BButton
                  variant="outline-primary"
                  @click="$emit('search')"
                >
                  검색
                </BButton>
              </BInputGroupAppend>
            </BInputGroup>
          </b-form-group>
        </b-col>
      </b-row>
      <BRow>
        <BCol v-if="searchedSnsList != null && searchedSnsList.length == 0">
          <BCard border-variant="danger">
            <div>
              {{ username }}이 존재하지 않습니다.
            </div>
            <div>
              <BButton
                variant="outline-secondary"
                class="mt-1"
                :disabled="isSavingBlindSns"
                @click="$emit('registerBlindSns')"
              >
                <BSpinner
                  v-if="isSavingBlindSns"
                  small
                />
                가계정 등록
              </BButton>
            </div>
          </BCard>
        </BCol>
        <BCol v-else>
          <div
            v-for="sns in searchedSnsList"
            :key="sns.idx"
            class="mb-1"
          >
            <SnsCard
              :sns="sns"
              :isSelected="sns.idx === targetSnsIdx"
              @selectSns="$emit('selectSns', $event)"
            />
          </div>
        </BCol>
      </BRow>
      <b-row>
        <b-col>
          <b-button
            variant="outline-primary"
            class="mr-1"
            :disabled="!isValid"
            @click="$emit('ok')"
          >
            <b-spinner
              v-if="isSaving"
              small
            />
            확인
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="$emit('hidden')"
          >
            취소
          </b-button>
        </b-col>
      </b-row>
    </template>
  </tagby-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BSpinner,
  BInputGroup,
  BInputGroupAppend,
  BCard,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'
import TagbySidebar from '@/components/TagbySidebar.vue'
import SnsCard from './SnsCard.vue'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BSpinner,
    BInputGroup,
    BInputGroupAppend,
    SnsCard,
    BCard,

    TagbySidebar,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    isSaving: {
      type: Boolean,
      required: true,
    },
    isValid: {
      type: Boolean,
      required: true,
    },
    username: {
      type: String,
      default: '',
    },
    targetSnsIdx: {
      type: Number,
    },
    searchedSnsList: {
      type: Array,
    },
    isSavingBlindSns: {
      type: Boolean,
    },
  },
  setup() {

  },
})
</script>
