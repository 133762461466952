<template>
  <div>
    <BAlert
      variant="danger"
      :show="hasLoadError"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <BLink
          class="alert-link"
          :to="{ name: 'member-inf-list'}"
        >
          User List
        </BLink>
        for other users.
      </div>
    </BAlert>
    <BRow>
      <BCol
        cols="12"
        xl="8"
        lg="8"
        md="7"
      >
        <InfoCard
          v-if="adv && brandSns"
          :adv="adv"
          :brandSns="brandSns"
        />
        <PartnershipListCard :partnershipList="partnershipList" />
      </BCol>
      <BCol
        cols="12"
        xl="4"
        lg="4"
        md="5"
      >
        <ActionCard
          :isWaitingTicket="isWaitingTicket"
          :brandSns="brandSns"
          @makeWebAuthTicket="makeWebAuthTicket"
          @turnOnAddPartnershipSidebar="turnOnAddPartnershipSidebar"
          @turnOnDeleteBrandSnsModal="turnOnDeleteBrandSnsModal"
        />
        <TagbyTimeline
          title="타임라인"
          :timelineData="timelines"
        />
      </BCol>
    </BRow>
    <MakeTicketModal
      :visible="visibleMakeTicketModal"
      :ticketLink="ticketLink"
      @hidden="turnOffMakeTicketModal"
    />
    <AddPartnershipSidebar
      :visible="visibleAddPartnershipSidebar"
      :isSaving="isSavingPartnership"
      :isValid="isValidPartnership"
      :username="partnershipUsername"
      :targetSnsIdx="targetSnsIdx"
      :searchedSnsList="searchedSnsList"
      :isSavingBlindSns="isSavingBlindSns"
      @inputUsername="inputPartnershipUsername"
      @ok="addPartnership"
      @hidden="turnOffAddPartnershipSidebar"
      @search="fetchSnsList"
      @selectSns="selectSns"
      @registerBlindSns="registerBlindSns"
    />
    <DeleteBrandSnsModal
      :visible="visibleDeleteBrandSnsModal"
      @ok="deleteBrandSns"
      @hidden="turnOffDeleteBrandSnsModal"
    />

  </div>
</template>

<script>
import {
  BAlert,
  BLink,
  BRow,
  BCol,
} from 'bootstrap-vue'
import viewModel from './viewModel'
import InfoCard from './components/InfoCard.vue'
import ActionCard from './components/ActionCard.vue'
import MakeTicketModal from './components/MakeTicketModal.vue'
import PartnershipListCard from './components/PartnershipListCard.vue'
import AddPartnershipSidebar from './components/AddPartnershipSidebar.vue'
import TagbyTimeline from '@/components/TagbyTimeline.vue'
import DeleteBrandSnsModal from './components/DeleteBrandSnsModal.vue'

export default {
  components: {
    BAlert,
    BLink,
    BRow,
    BCol,
    InfoCard,
    ActionCard,
    MakeTicketModal,
    PartnershipListCard,
    AddPartnershipSidebar,
    TagbyTimeline,
    DeleteBrandSnsModal,
  },
  setup() {
    return {
      ...viewModel(),
    }
  },
}
</script>
