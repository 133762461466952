import {
  ref,
  onMounted,
  onUnmounted,
  computed,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import storeModule from './storeModule'
import { getRouterIdx } from '@/utils/router'

const routerIdx = ref()

export default function () {
  // 모든 기능에서 공통적으로 사용
  const APP_STORE_MODULE_NAME = 'app-brand-sns-detail'

  routerIdx.value = getRouterIdx()

  const toast = useToast()
  const makeToast = (variant, title, text) => toast({
    component: ToastificationContent,
    props: {
      title,
      text,
      variant,
      icon: 'AlertTriangleIcon',
    },
  })

  const brandSns = ref()
  const adv = ref()
  const hasLoadError = ref(false)

  const fetchDetail = () => store.dispatch(`${APP_STORE_MODULE_NAME}/fetchDetail`,
    { idx: router.currentRoute.params.idx })
    .then(response => {
      brandSns.value = response.data.data.brand_sns
      adv.value = response.data.data.adv
    }).catch(() => {
      hasLoadError.value = true
    }).finally(() => {
    })

  const timelines = ref([])
  const fetchTimelines = () => store.dispatch(`${APP_STORE_MODULE_NAME}/fetchTimelines`,
    { idx: router.currentRoute.params.idx })
    .then(response => {
      timelines.value = response.data.data
    }).catch(() => {
    }).finally(() => {
    })

  const isWaitingTicket = ref(false)
  const ticketLink = ref()
  const visibleMakeTicketModal = ref(false)
  const turnOnMakeTicketModal = () => {
    visibleMakeTicketModal.value = true
  }
  const turnOffMakeTicketModal = () => {
    visibleMakeTicketModal.value = false
  }
  const makeWebAuthTicket = () => {
    ticketLink.value = null
    isWaitingTicket.value = true
    store.dispatch(`${APP_STORE_MODULE_NAME}/makeWebAuthTicket`,
      { idx: router.currentRoute.params.idx })
      .then(response => {
        ticketLink.value = response.data.data.link_url
        turnOnMakeTicketModal()
        fetchTimelines()
      }).catch(() => {
        makeToast('danger', '웹 인증 티켓 발급에 실패 했습니다')
      }).finally(() => {
        isWaitingTicket.value = false
      })
  }

  const partnershipList = ref([])
  const fetchPartnershipList = () => store.dispatch(`${APP_STORE_MODULE_NAME}/fetchPartnershipList`,
    { idx: router.currentRoute.params.idx })
    .then(response => {
      partnershipList.value = response.data.data
      fetchTimelines()
    }).catch(error => {
      const errorMessage = error?.response?.data?.msg
      if (errorMessage === 'Brand SNS token is expired') {
        makeToast('danger', '파트너십 목록을 불러오는데 실패했습니다', '인증토큰이 만료됐습니다. 재로그인이 필요합니다')
      } else {
        makeToast('danger', '파트너십 목록을 불러오는데 실패했습니다')
      }
    }).finally(() => {
    })

  const targetSnsIdx = ref()
  const searchedSnsList = ref()
  const partnershipUsername = ref()
  const inputPartnershipUsername = value => {
    targetSnsIdx.value = null
    searchedSnsList.value = null
    partnershipUsername.value = value
  }
  const isSavingPartnership = ref(false)
  const isValidPartnership = computed(() => Boolean(targetSnsIdx.value))
  const visibleAddPartnershipSidebar = ref(false)
  const turnOnAddPartnershipSidebar = () => {
    targetSnsIdx.value = null
    searchedSnsList.value = null
    partnershipUsername.value = null
    visibleAddPartnershipSidebar.value = true
  }
  const turnOffAddPartnershipSidebar = () => {
    visibleAddPartnershipSidebar.value = false
  }
  const addPartnership = () => {
    isSavingPartnership.value = true
    store.dispatch(`${APP_STORE_MODULE_NAME}/addPartnership`,
      {
        idx: routerIdx.value,
        sns_idx: targetSnsIdx.value,
      })
      .then(() => {
        makeToast('primary', '파트너십을 추가하도록 요청을 보냈습니다')
        fetchPartnershipList()
        fetchTimelines()
        turnOffAddPartnershipSidebar()
      }).catch(error => {
        const errorMessage = error?.response?.data?.msg
        if (errorMessage === 'Brand SNS token is expired') {
          makeToast('danger', '파트너십을 추가하는데 실패했습니다', '인증토큰이 만료됐습니다. 재로그인이 필요합니다')
        } else if (errorMessage === 'Creators can\'t use MES') {
          makeToast('danger', '파트너십을 추가하는데 실패했습니다', '인플루언서가 수익화 정책을 만족하지 않습니다')
        } else {
          makeToast('danger', '파트너십을 추가하는데 실패했습니다')
        }
      }).finally(() => {
        isSavingPartnership.value = false
      })
  }

  const visibleDeleteBrandSnsModal = ref(false)
  const turnOnDeleteBrandSnsModal = () => {
    visibleDeleteBrandSnsModal.value = true
  }
  const turnOffDeleteBrandSnsModal = () => {
    visibleDeleteBrandSnsModal.value = false
  }
  const deleteBrandSns = () => {
    store.dispatch(`${APP_STORE_MODULE_NAME}/deleteBrandSns`, {
      brand_sns_idx: routerIdx.value,
    }).then(() => {
      makeToast('primary', '브랜드 SNS 계정 삭제에 성공했습니다')
    }).catch(() => {
      makeToast('danger', '브랜드 SNS 계정 삭제에 실패했습니다')
    })
  }

  const fetchSnsList = () => {
    store.dispatch(`${APP_STORE_MODULE_NAME}/fetchSnsList`, {
      username: partnershipUsername.value,
    }).then(response => {
      searchedSnsList.value = response.data.data
    }).catch(() => {
      makeToast('danger', 'SNS 검색에 실패했습니다')
    })
  }

  const selectSns = sns => {
    if (sns.state === 'WAIT') return
    targetSnsIdx.value = sns.idx
  }

  const isSavingBlindSns = ref(false)
  const registerBlindSns = () => {
    isSavingBlindSns.value = true
    store.dispatch(`${APP_STORE_MODULE_NAME}/registerBlindSns`, {
      brand_sns_idx: routerIdx.value,
      username: partnershipUsername.value,
    }).then(() => {
      makeToast('primary', '가계정 등록에 성공했습니다', '정보를 불러오는데 최대 5분 정도 시간이 소요될 수 있습니다')
      fetchSnsList()
      fetchTimelines()
    }).catch(() => {
      makeToast('danger', '가계정 등록에 실패했습니다')
    }).finally(() => {
      isSavingBlindSns.value = false
    })
  }

  onMounted(() => {
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, storeModule)
    fetchDetail()
    fetchTimelines()
    fetchPartnershipList()
  })
  onUnmounted(() => {
    if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
  })

  return {
    hasLoadError,
    brandSns,
    adv,
    fetchDetail,
    isWaitingTicket,
    ticketLink,
    visibleMakeTicketModal,
    turnOnMakeTicketModal,
    turnOffMakeTicketModal,
    makeWebAuthTicket,
    visibleAddPartnershipSidebar,
    turnOnAddPartnershipSidebar,
    turnOffAddPartnershipSidebar,
    partnershipList,
    fetchPartnershipList,
    partnershipUsername,
    inputPartnershipUsername,
    isSavingPartnership,
    isValidPartnership,
    addPartnership,
    timelines,
    fetchTimelines,

    visibleDeleteBrandSnsModal,
    turnOnDeleteBrandSnsModal,
    turnOffDeleteBrandSnsModal,
    deleteBrandSns,

    searchedSnsList,
    fetchSnsList,
    targetSnsIdx,
    selectSns,
    registerBlindSns,
    isSavingBlindSns,
  }
}
