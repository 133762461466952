<template>
  <BCard
    :border-variant="isSelected ? 'primary': null"
    style="cursor: pointer"
    @click="$emit('selectSns', sns)"
  >
    <div
      class="d-flex align-items-center"
    >
      <div
        class="mr-1"
        style="cursor: pointer"
        @click="pageOpen(sns.landing_url)"
      >
        <BAvatar
          size="40"
          variant="light"
          :src="sns.profile_img_url"
        />
      </div>
      <div class="mr-1">
        {{ sns.username }}
      </div>
      <div class="mr-1">
        [idx: {{ sns.idx }}]
      </div>
      <div class="mr-1">
        <BBadge :variant="resolveState(sns.state)">
          {{ sns.state }}
        </BBadge>
      </div>
    </div>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BAvatar,
  BBadge,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BCard,
    BAvatar,
    BBadge,
  },
  props: {
    sns: {
      type: Object,
    },
    isSelected: {
      type: Boolean,
    },
  },
  setup() {
    const pageOpen = url => window.open(url, '_blank')
    const resolveState = state => {
      if (state === 'ACTIVE') {
        return 'primary'
      }
      if (state === 'RELOGIN') {
        return 'info'
      }
      if (state === 'WAIT') {
        return 'warning'
      }
      return 'secondary'
    }
    return {
      pageOpen,
      resolveState,
    }
  },
})
</script>
