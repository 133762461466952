import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDetail(ctx, { idx }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/brand-sns/${idx}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    makeWebAuthTicket(ctx, { idx, ...params }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/brand-sns/${idx}/make-web-auth-ticket/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPartnership(ctx, { idx, ...params }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/brand-sns/${idx}/add-partnership/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPartnershipList(ctx, { idx }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/brand-sns/${idx}/partnership/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTimelines(ctx, { idx }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/brand-sns/${idx}/timelines/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteBrandSns(ctx, { idx, ...params }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/brand-sns/delete-brand-sns/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSnsList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/brand-sns/sns-list/`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    registerBlindSns(ctx, { idx, ...params }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/brand-sns/register-blind-sns/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    refreshSns(ctx, { idx, ...params }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/sns/refresh-sns/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
