<template>
  <b-card :title="title">
    <app-timeline>
      <app-timeline-item
        v-for="timeline in timelineData"
        :key="timeline.idx"
        :title="resolveTimelineTitle(timeline)"
        :subtitle="resolveTimelineText(timeline)"
        :time="resolveDatetime(timeline.created_at)"
        variant="success"
      />
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import moment from 'moment'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    title: {
      type: String,
      default: '타임라인',
    },
    timelineData: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    resolveTimelineTitle(timeline) {
      if (timeline.admin == null) {
        return timeline.title
      }
      return `${timeline.title} (${timeline.admin.name})`
    },
    resolveDatetime(datetime) {
      return moment(datetime).format('YYYY-MM-DD HH:mm:ss')
    },
    resolveTimelineText(timeline) {
      return timeline.text
    },
  },
}
</script>

<style>

</style>
