<template>
  <BListGroupItem
    class="d-flex justify-content-between align-items-center"
  >
    <span>{{ username }}</span>
    <BBadge
      :variant="statusVariant"
      pill
      class="badge-round"
    >
      {{ permissionStatus }}
    </BBadge>
  </BListGroupItem>
</template>

<script>
import {
  BListGroupItem,
  BBadge,
} from 'bootstrap-vue'
import {
  computed,
} from '@vue/composition-api'

export default {
  components: {
    BListGroupItem,
    BBadge,
  },
  props: {
    username: {
      type: String,
      required: true,
    },
    permissionStatus: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const statusVariant = computed(() => {
      if (props.permissionStatus === 'Approved') {
        return 'primary'
      } if (props.permissionStatus === 'Pending Approval') {
        return 'success'
      } if (props.permissionStatus === 'Canceled') {
        return 'secondary'
      }
      return 'danger'
    })
    return {
      statusVariant,
    }
  },
}
</script>
