<template>
  <BModal
    :visible="visible"
    ok-title="확인"
    ok-only
    centered
    title="웹 인증 티켓 발급"
    @ok="$emit('ok')"
    @hidden="$emit('hidden')"
  >
    {{ ticketLink }}
    <TagbyCopyIcon
      :value="ticketLink"
    />
  </BModal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'
import TagbyCopyIcon from '@/components/TagbyCopyIcon.vue'

export default {
  components: {
    BModal,
    TagbyCopyIcon,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    ticketLink: {
      type: String,
      default: null,
    },
  },
}
</script>
